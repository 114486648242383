import service from '@/utils/request';
export var createChannelMember = function createChannelMember(data) {
  return service({
    url: "/channel_member/create",
    method: 'post',
    data: data
  });
};
export var deleteChannelMember = function deleteChannelMember(data) {
  return service({
    url: "/channel_member/delete",
    method: 'delete',
    data: data
  });
};
export var updateChannelMember = function updateChannelMember(data) {
  return service({
    url: "/channel_member/update",
    method: 'put',
    data: data
  });
};
export var getChannelMemberList = function getChannelMemberList(params) {
  return service({
    url: "/channel_member/list",
    method: 'get',
    params: params
  });
};
export var getChannelMemberOptions = function getChannelMemberOptions(params) {
  return service({
    url: "/channel_member/user_options",
    method: 'get',
    params: params
  });
};
export var getChannelMemberLevelOptions = function getChannelMemberLevelOptions(params) {
  return service({
    url: "/channel_member/level_options",
    method: 'get',
    params: params
  });
};
export var getChannelMemberWithdrawList = function getChannelMemberWithdrawList(params) {
  return service({
    url: "/channel_member/tx_list",
    method: 'get',
    params: params
  });
};
export var auditChannelMemberWithdraw = function auditChannelMemberWithdraw(data) {
  return service({
    url: "/channel_member/tx_audit",
    method: 'post',
    data: data
  });
};